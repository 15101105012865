/*
 * This file is generated by graphql-codegen. Do NOT edit directly.
 * Run `npm run graphql:caire-api` to regenerate.
 */
import * as ServiceTypes from '@/lib/graphql/types';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = { ssr: false, fetchPolicy: 'network-only' } as const;
export type GuidedJourneyReadPositionDetailsFragment = {
  __typename?: 'ContentView';
  id: any;
  createdAt: any;
  contentType: ServiceTypes.ContentfulContentType;
  contentId: string;
  parentContentId?: string | null;
  parentContentType?: ServiceTypes.ContentfulContentType | null;
  isContentAssignmentComplete: boolean;
  taskId?: any | null;
};

export type HealthCoachClinicalNoteFragment = {
  __typename?: 'HealthCoachNote';
  plan?: {
    __typename?: 'HealthCoachPlanNote';
    postVisitSummary?: string | null;
  } | null;
};

export type HistoricalClinicalNoteFragment = {
  __typename?: 'SOAPNote';
  plan?: { __typename?: 'PlanNote'; note?: string | null } | null;
};

export type PrescriberClinicalNoteFragment = {
  __typename?: 'PrescriberNote';
  plan?: {
    __typename?: 'PrescriberPlanNote';
    postVisitSummary?: string | null;
  } | null;
};

export type CancelAppointmentMutationVariables = ServiceTypes.Exact<{
  cancelAppointmentId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type CancelAppointmentMutation = {
  __typename?: 'Mutation';
  cancelAppointment: {
    __typename?: 'Appointment';
    id: any;
    programId: any;
    appointmentTypeId: any;
    taskId?: any | null;
    patientId: any;
    staffId: any;
    summary: string;
    description: string;
    startTime: any;
    endTime: any;
    timezone: string;
    status: ServiceTypes.AppointmentStatus;
    createdByType: ServiceTypes.ActorType;
    createdBy: string;
    updatedByType: ServiceTypes.ActorType;
    updatedBy: string;
    createdAt: any;
    updatedAt: any;
    appointmentType: {
      __typename?: 'AppointmentType';
      id: any;
      programId: any;
      name: string;
      slug: string;
      displayName: string;
      duration: number;
      descriptionTemplate: string;
      allowedRole: ServiceTypes.StaffRole;
      postFormId?: any | null;
      createdAt: any;
      updatedAt: any;
    };
    staff: {
      __typename?: 'Staff';
      bio?: string | null;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      profileImageUrl?: string | null;
      role: ServiceTypes.StaffRole;
      status: ServiceTypes.StaffStatus;
      createdAt: any;
      updatedAt: any;
      id: any;
      professionalLicenses: Array<{
        __typename?: 'ProfessionalLicense';
        licenseState: ServiceTypes.StateCode;
        type: ServiceTypes.ProfessionalLicenseType;
        createdAt: any;
        id: string;
        licenseNumber: string;
        status: ServiceTypes.ProfessionalLicenseStatus;
        updatedAt: any;
      }>;
      programs: Array<{
        __typename?: 'StaffProgram';
        programId: any;
        status: ServiceTypes.StaffProgramStatus;
        createdAt: any;
        id: string;
        updatedAt: any;
        program: {
          __typename?: 'Program';
          createdAt: any;
          description: string;
          id: any;
          name: string;
          slug: string;
          updatedAt: any;
        };
      }>;
    };
  };
};

export type MarkGuidedJourneyCompleteMutationVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
  id: ServiceTypes.Scalars['String']['input'];
  guidedJourneyId: ServiceTypes.Scalars['String']['input'];
  taskId?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['UUID']['input']>;
}>;

export type MarkGuidedJourneyCompleteMutation = {
  __typename?: 'Mutation';
  createContentView: { __typename?: 'ContentView'; id: any; contentId: string };
};

export type MarkGuidedJourneyPageReadMutationVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
  id: ServiceTypes.Scalars['String']['input'];
  guidedJourneyId: ServiceTypes.Scalars['String']['input'];
  taskId?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['UUID']['input']>;
}>;

export type MarkGuidedJourneyPageReadMutation = {
  __typename?: 'Mutation';
  createContentView: { __typename?: 'ContentView'; id: any; contentId: string };
};

export type MarkPageReadMutationVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
  id: ServiceTypes.Scalars['String']['input'];
  taskId?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['UUID']['input']>;
  markTaskComplete?: ServiceTypes.InputMaybe<
    ServiceTypes.Scalars['Boolean']['input']
  >;
}>;

export type MarkPageReadMutation = {
  __typename?: 'Mutation';
  createContentView: { __typename?: 'ContentView'; id: any; contentId: string };
};

export type CreateAppointmentMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreateAppointmentInput;
}>;

export type CreateAppointmentMutation = {
  __typename?: 'Mutation';
  createAppointment: {
    __typename?: 'Appointment';
    id: any;
    appointmentTypeId: any;
    patientId: any;
    staffId: any;
    videoConferenceUrl?: string | null;
    startTime: any;
    appointmentType: { __typename?: 'AppointmentType'; displayName: string };
    staff: {
      __typename?: 'Staff';
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      profileImageUrl?: string | null;
      professionalLicenses: Array<{
        __typename?: 'ProfessionalLicense';
        licenseState: ServiceTypes.StateCode;
        type: ServiceTypes.ProfessionalLicenseType;
        createdAt: any;
        id: string;
        licenseNumber: string;
        status: ServiceTypes.ProfessionalLicenseStatus;
        updatedAt: any;
      }>;
    };
  };
};

export type CreateEnrollmentMutationVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programSlug: ServiceTypes.Scalars['String']['input'];
  firstName: ServiceTypes.Scalars['String']['input'];
  lastName: ServiceTypes.Scalars['String']['input'];
  birthDate: ServiceTypes.Scalars['Date']['input'];
  postalCode: ServiceTypes.Scalars['String']['input'];
}>;

export type CreateEnrollmentMutation = {
  __typename?: 'Mutation';
  createEnrollment: {
    __typename?: 'Enrollment';
    id: string;
    createdAt: any;
    updatedAt: any;
    programId: any;
    enrollmentStatus: ServiceTypes.EnrollmentStatus;
  };
};

export type CreatePatientMutationVariables = ServiceTypes.Exact<{
  input: ServiceTypes.CreatePatientInput;
}>;

export type CreatePatientMutation = {
  __typename?: 'Mutation';
  createPatient: {
    __typename?: 'Patient';
    id: any;
    firstName: string;
    lastName: string;
    birthDate: any;
    birthSex: ServiceTypes.BirthSex;
    preferredName?: string | null;
    contactPreference?: ServiceTypes.ContactPreference | null;
    addresses?: Array<{
      __typename?: 'Address';
      city: string;
      line1: string;
      line2?: string | null;
      state: ServiceTypes.StateCode;
      postalCode: string;
      country: string;
    }> | null;
    phoneNumbers?: Array<{
      __typename?: 'PhoneNumber';
      use: ServiceTypes.ContactUse;
      value: string;
    }> | null;
    pronouns?: {
      __typename?: 'Pronouns';
      value: ServiceTypes.PronounCode;
    } | null;
    genderIdentity?: {
      __typename?: 'GenderIdentity';
      value: ServiceTypes.GenderIdentityCode;
    } | null;
  };
};

export type UpsertFormResponseByTaskIdMutationVariables = ServiceTypes.Exact<{
  taskId: ServiceTypes.Scalars['UUID']['input'];
  input: ServiceTypes.UpsertFormResponseInput;
}>;

export type UpsertFormResponseByTaskIdMutation = {
  __typename?: 'Mutation';
  upsertFormResponseByTaskId: {
    __typename?: 'FormResponse';
    id: any;
    patientId: any;
    programId: any;
    formVersionId: any;
    response?: any | null;
    createdAt: any;
    updatedAt: any;
    submittedAt?: any | null;
    task?: { __typename?: 'Task'; id: any } | null;
    formVersion: { __typename?: 'FormVersion'; id: any };
  };
};

export type ResetUserPasswordMutationVariables = ServiceTypes.Exact<{
  resetUserPasswordId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type ResetUserPasswordMutation = {
  __typename?: 'Mutation';
  resetUserPassword?: any | null;
};

export type UpdatePatientMutationVariables = ServiceTypes.Exact<{
  updatePatientId: ServiceTypes.Scalars['UUID']['input'];
  input: ServiceTypes.UpdatePatientInput;
}>;

export type UpdatePatientMutation = {
  __typename?: 'Mutation';
  updatePatient: { __typename?: 'Patient'; firstName: string };
};

export type SendPatientEmailVerificationMutationVariables = ServiceTypes.Exact<{
  sendPatientEmailVerificationId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type SendPatientEmailVerificationMutation = {
  __typename?: 'Mutation';
  sendPatientEmailVerification: {
    __typename?: 'SendVerificationEmailOutput';
    status: string;
  };
};

export type UpdateAppointmentMutationVariables = ServiceTypes.Exact<{
  updateAppointmentId: ServiceTypes.Scalars['UUID']['input'];
  input: ServiceTypes.UpdateAppointmentInput;
}>;

export type UpdateAppointmentMutation = {
  __typename?: 'Mutation';
  updateAppointment: {
    __typename?: 'Appointment';
    id: any;
    programId: any;
    appointmentTypeId: any;
    taskId?: any | null;
    patientId: any;
    staffId: any;
    summary: string;
    description: string;
    startTime: any;
    endTime: any;
    timezone: string;
    status: ServiceTypes.AppointmentStatus;
    createdByType: ServiceTypes.ActorType;
    createdBy: string;
    updatedByType: ServiceTypes.ActorType;
    updatedBy: string;
    createdAt: any;
    updatedAt: any;
    appointmentType: {
      __typename?: 'AppointmentType';
      id: any;
      programId: any;
      name: string;
      slug: string;
      displayName: string;
      duration: number;
      descriptionTemplate: string;
      allowedRole: ServiceTypes.StaffRole;
      postFormId?: any | null;
      createdAt: any;
      updatedAt: any;
    };
    staff: {
      __typename?: 'Staff';
      bio?: string | null;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      profileImageUrl?: string | null;
      role: ServiceTypes.StaffRole;
      status: ServiceTypes.StaffStatus;
      createdAt: any;
      updatedAt: any;
      id: any;
      professionalLicenses: Array<{
        __typename?: 'ProfessionalLicense';
        licenseState: ServiceTypes.StateCode;
        type: ServiceTypes.ProfessionalLicenseType;
        createdAt: any;
        id: string;
        licenseNumber: string;
        status: ServiceTypes.ProfessionalLicenseStatus;
        updatedAt: any;
      }>;
      programs: Array<{
        __typename?: 'StaffProgram';
        programId: any;
        status: ServiceTypes.StaffProgramStatus;
        createdAt: any;
        id: string;
        updatedAt: any;
        program: {
          __typename?: 'Program';
          createdAt: any;
          description: string;
          id: any;
          name: string;
          slug: string;
          updatedAt: any;
        };
      }>;
    };
  };
};

export type AppointmentQueryVariables = ServiceTypes.Exact<{
  appointmentId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type AppointmentQuery = {
  __typename?: 'Query';
  appointment: {
    __typename?: 'Appointment';
    id: any;
    programId: any;
    appointmentTypeId: any;
    taskId?: any | null;
    patientId: any;
    staffId: any;
    summary: string;
    description: string;
    startTime: any;
    endTime: any;
    timezone: string;
    status: ServiceTypes.AppointmentStatus;
    createdByType: ServiceTypes.ActorType;
    createdBy: string;
    updatedByType: ServiceTypes.ActorType;
    updatedBy: string;
    createdAt: any;
    updatedAt: any;
    videoConferenceUrl?: string | null;
    appointmentType: {
      __typename?: 'AppointmentType';
      id: any;
      programId: any;
      name: string;
      slug: string;
      displayName: string;
      duration: number;
      descriptionTemplate: string;
      allowedRole: ServiceTypes.StaffRole;
      postFormId?: any | null;
      createdAt: any;
      updatedAt: any;
    };
    staff: {
      __typename?: 'Staff';
      bio?: string | null;
      firstName: string;
      lastName: string;
      preferredName?: string | null;
      profileImageUrl?: string | null;
      role: ServiceTypes.StaffRole;
      status: ServiceTypes.StaffStatus;
      createdAt: any;
      updatedAt: any;
      id: any;
      professionalLicenses: Array<{
        __typename?: 'ProfessionalLicense';
        licenseState: ServiceTypes.StateCode;
        type: ServiceTypes.ProfessionalLicenseType;
        createdAt: any;
        id: string;
        licenseNumber: string;
        status: ServiceTypes.ProfessionalLicenseStatus;
        updatedAt: any;
      }>;
      programs: Array<{
        __typename?: 'StaffProgram';
        programId: any;
        status: ServiceTypes.StaffProgramStatus;
        createdAt: any;
        id: string;
        updatedAt: any;
        program: {
          __typename?: 'Program';
          createdAt: any;
          description: string;
          id: any;
          name: string;
          slug: string;
          updatedAt: any;
        };
      }>;
    };
    patient: {
      __typename?: 'Patient';
      id: any;
      createdAt: any;
      updatedAt: any;
      firstName: string;
      lastName: string;
      birthSex: ServiceTypes.BirthSex;
      birthDate: any;
      preferredName?: string | null;
      contactPreference?: ServiceTypes.ContactPreference | null;
      genderIdentity?: {
        __typename?: 'GenderIdentity';
        value: ServiceTypes.GenderIdentityCode;
        text: string;
      } | null;
      pronouns?: {
        __typename?: 'Pronouns';
        value: ServiceTypes.PronounCode;
        text: string;
      } | null;
      phoneNumbers?: Array<{
        __typename?: 'PhoneNumber';
        value: string;
        use: ServiceTypes.ContactUse;
      }> | null;
      emailAddresses?: Array<{
        __typename?: 'EmailAddress';
        value: string;
        use: ServiceTypes.ContactUse;
      }> | null;
      addresses?: Array<{
        __typename?: 'Address';
        line1: string;
        line2?: string | null;
        city: string;
        state: ServiceTypes.StateCode;
        postalCode: string;
        country: string;
      }> | null;
      enrollments: Array<{
        __typename?: 'Enrollment';
        id: string;
        createdAt: any;
        updatedAt: any;
        programId: any;
        enrollmentStatus: ServiceTypes.EnrollmentStatus;
        enrolledUntil?: any | null;
        enrolledAt?: any | null;
        unenrolledAt?: any | null;
        payer: {
          __typename?: 'Payer';
          type: string;
          organizationId: any;
          organizationEmployeeId?: any | null;
          eligibilityType: ServiceTypes.EligibilityType;
        };
        program: {
          __typename?: 'Program';
          id: any;
          createdAt: any;
          updatedAt: any;
          name: string;
          slug: string;
          description: string;
          config?: {
            __typename?: 'ProgramConfigResponse';
            roleNameMapping?: any | null;
          } | null;
        };
      }>;
    };
  };
};

export type EligibilityWithAllowanceQueryVariables = ServiceTypes.Exact<{
  programSlug: ServiceTypes.Scalars['String']['input'];
  firstName: ServiceTypes.Scalars['String']['input'];
  lastName: ServiceTypes.Scalars['String']['input'];
  birthDate: ServiceTypes.Scalars['Date']['input'];
  postalCode: ServiceTypes.Scalars['String']['input'];
}>;

export type EligibilityWithAllowanceQuery = {
  __typename?: 'Query';
  eligibilityWithAllowance: {
    __typename?: 'EligibilityWithAllowance';
    isEligible: boolean;
    ineligibleReason?: ServiceTypes.IneligibleReason | null;
  };
};

export type GetEncounterByIdQueryVariables = ServiceTypes.Exact<{
  encounterId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type GetEncounterByIdQuery = {
  __typename?: 'Query';
  encounter: {
    __typename?: 'Encounter';
    id: any;
    patientId: any;
    clinicalNoteType?: ServiceTypes.ClinicalNoteType | null;
    staff: {
      __typename?: 'Staff';
      firstName: string;
      id: any;
      lastName: string;
      preferredName?: string | null;
      profileImageUrl?: string | null;
      professionalLicenses: Array<{
        __typename?: 'ProfessionalLicense';
        type: ServiceTypes.ProfessionalLicenseType;
      }>;
    };
    appointment?: {
      __typename?: 'Appointment';
      startTime: any;
      videoConferenceUrl?: string | null;
      appointmentType: { __typename?: 'AppointmentType'; name: string };
    } | null;
    clinicalNote: {
      __typename?: 'ClinicalNote';
      body?:
        | {
            __typename?: 'HealthCoachNote';
            plan?: {
              __typename?: 'HealthCoachPlanNote';
              postVisitSummary?: string | null;
            } | null;
          }
        | {
            __typename?: 'PrescriberNote';
            plan?: {
              __typename?: 'PrescriberPlanNote';
              postVisitSummary?: string | null;
            } | null;
          }
        | {
            __typename?: 'SOAPNote';
            plan?: { __typename?: 'PlanNote'; note?: string | null } | null;
          }
        | null;
    };
  };
};

export type ListEncountersQueryVariables = ServiceTypes.Exact<{
  filters?: ServiceTypes.InputMaybe<ServiceTypes.ListEncounterFilters>;
}>;

export type ListEncountersQuery = {
  __typename?: 'Query';
  encounters: Array<{
    __typename?: 'Encounter';
    id: any;
    patientId: any;
    clinicalNoteType?: ServiceTypes.ClinicalNoteType | null;
    summary?: {
      __typename?: 'EncounterSummary';
      note?: string | null;
      nextSteps?: string | null;
      goals?: string | null;
    } | null;
    staff: {
      __typename?: 'Staff';
      firstName: string;
      id: any;
      lastName: string;
      preferredName?: string | null;
      profileImageUrl?: string | null;
      professionalLicenses: Array<{
        __typename?: 'ProfessionalLicense';
        type: ServiceTypes.ProfessionalLicenseType;
      }>;
    };
    appointment?: {
      __typename?: 'Appointment';
      startTime: any;
      videoConferenceUrl?: string | null;
      appointmentType: { __typename?: 'AppointmentType'; name: string };
    } | null;
  }>;
};

export type GuidedJourneyReadPositionQueryVariables = ServiceTypes.Exact<{
  patientId: ServiceTypes.Scalars['UUID']['input'];
  programId: ServiceTypes.Scalars['UUID']['input'];
  guidedJourneyId: ServiceTypes.Scalars['String']['input'];
  taskId?: ServiceTypes.InputMaybe<ServiceTypes.Scalars['UUID']['input']>;
}>;

export type GuidedJourneyReadPositionQuery = {
  __typename?: 'Query';
  contentViews: Array<{
    __typename?: 'ContentView';
    id: any;
    createdAt: any;
    contentType: ServiceTypes.ContentfulContentType;
    contentId: string;
    parentContentId?: string | null;
    parentContentType?: ServiceTypes.ContentfulContentType | null;
    isContentAssignmentComplete: boolean;
    taskId?: any | null;
  }>;
};

export type GetFormByIdQueryVariables = ServiceTypes.Exact<{
  formId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type GetFormByIdQuery = {
  __typename?: 'Query';
  form: {
    __typename?: 'Form';
    id: any;
    archivedAt?: any | null;
    createdAt: any;
    description?: string | null;
    name: string;
    programId: any;
    slug: string;
    updatedAt: any;
    draftVersion: { __typename?: 'FormVersion'; config: any; id: any };
    publishedVersion?: {
      __typename?: 'FormVersion';
      config: any;
      id: any;
    } | null;
  };
};

export type GetFormResponseByTaskIdQueryVariables = ServiceTypes.Exact<{
  taskId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type GetFormResponseByTaskIdQuery = {
  __typename?: 'Query';
  formResponseByTaskId?: {
    __typename?: 'FormResponse';
    id: any;
    response?: any | null;
    pageNumber?: number | null;
  } | null;
};

export type TaskQueryVariables = ServiceTypes.Exact<{
  taskId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type TaskQuery = {
  __typename?: 'Query';
  task: { __typename?: 'Task'; id: any; eventDueAt?: any | null };
};

export type TaskObjectRouteQueryVariables = ServiceTypes.Exact<{
  taskId: ServiceTypes.Scalars['UUID']['input'];
}>;

export type TaskObjectRouteQuery = {
  __typename?: 'Query';
  task: {
    __typename?: 'Task';
    id: any;
    name: string;
    status: ServiceTypes.TaskStatus;
    taskDueAt: any;
    taskExpireAt?: any | null;
    taskType: ServiceTypes.TaskType;
    relatedObjectType?: ServiceTypes.TaskRelatedObject | null;
    relatedObjectId?: string | null;
  };
};

export type WhoAmIQueryVariables = ServiceTypes.Exact<{ [key: string]: never }>;

export type WhoAmIQuery = {
  __typename?: 'Query';
  whoAmI: {
    __typename?: 'Patient';
    id: any;
    firstName: string;
    lastName: string;
    birthDate: any;
    birthSex: ServiceTypes.BirthSex;
    createdAt: any;
    updatedAt: any;
    preferredName?: string | null;
    contactPreference?: ServiceTypes.ContactPreference | null;
    genderIdentity?: {
      __typename?: 'GenderIdentity';
      text: string;
      value: ServiceTypes.GenderIdentityCode;
    } | null;
    phoneNumbers?: Array<{
      __typename?: 'PhoneNumber';
      use: ServiceTypes.ContactUse;
      value: string;
    }> | null;
    emailAddresses?: Array<{
      __typename?: 'EmailAddress';
      value: string;
    }> | null;
    pronouns?: {
      __typename?: 'Pronouns';
      text: string;
      value: ServiceTypes.PronounCode;
    } | null;
    addresses?: Array<{
      __typename?: 'Address';
      city: string;
      line1: string;
      line2?: string | null;
      state: ServiceTypes.StateCode;
      postalCode: string;
      country: string;
    }> | null;
    enrollments: Array<{
      __typename?: 'Enrollment';
      id: string;
      createdAt: any;
      updatedAt: any;
      programId: any;
      enrollmentStatus: ServiceTypes.EnrollmentStatus;
      enrolledUntil?: any | null;
      enrolledAt?: any | null;
      unenrolledAt?: any | null;
      payer: {
        __typename?: 'Payer';
        organizationEmployeeId?: any | null;
        organizationId: any;
        type: string;
      };
      program: {
        __typename?: 'Program';
        createdAt: any;
        description: string;
        id: any;
        name: string;
        slug: string;
        updatedAt: any;
        config?: {
          __typename?: 'ProgramConfigResponse';
          roleNameMapping?: any | null;
        } | null;
      };
    }>;
  };
};

export const GuidedJourneyReadPositionDetailsFragmentDoc = gql`
  fragment GuidedJourneyReadPositionDetails on ContentView {
    id
    createdAt
    contentType
    contentId
    parentContentId
    parentContentType
    isContentAssignmentComplete
    taskId
  }
`;
export const HealthCoachClinicalNoteFragmentDoc = gql`
  fragment HealthCoachClinicalNote on HealthCoachNote {
    plan {
      postVisitSummary
    }
  }
`;
export const HistoricalClinicalNoteFragmentDoc = gql`
  fragment HistoricalClinicalNote on SOAPNote {
    plan {
      note
    }
  }
`;
export const PrescriberClinicalNoteFragmentDoc = gql`
  fragment PrescriberClinicalNote on PrescriberNote {
    plan {
      postVisitSummary
    }
  }
`;
export const CancelAppointmentDocument = gql`
  mutation cancelAppointment($cancelAppointmentId: UUID!) {
    cancelAppointment(id: $cancelAppointmentId) {
      id
      programId
      appointmentTypeId
      appointmentType {
        id
        programId
        name
        slug
        displayName
        duration
        descriptionTemplate
        allowedRole
        postFormId
        createdAt
        updatedAt
      }
      taskId
      patientId
      staffId
      staff {
        bio
        firstName
        lastName
        preferredName
        professionalLicenses {
          licenseState
          type
          createdAt
          id
          licenseNumber
          status
          updatedAt
        }
        profileImageUrl
        programs {
          programId
          status
          createdAt
          id
          updatedAt
          program {
            createdAt
            description
            id
            name
            slug
            updatedAt
          }
        }
        role
        status
        createdAt
        updatedAt
        id
      }
      summary
      description
      startTime
      endTime
      timezone
      status
      createdByType
      createdBy
      updatedByType
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export type CancelAppointmentMutationFn = Apollo.MutationFunction<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;

/**
 * __useCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAppointmentMutation, { data, loading, error }] = useCancelAppointmentMutation({
 *   variables: {
 *      cancelAppointmentId: // value for 'cancelAppointmentId'
 *   },
 * });
 */
export function useCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CancelAppointmentMutation,
    CancelAppointmentMutationVariables
  >(CancelAppointmentDocument, options);
}
export type CancelAppointmentMutationHookResult = ReturnType<
  typeof useCancelAppointmentMutation
>;
export type CancelAppointmentMutationResult =
  Apollo.MutationResult<CancelAppointmentMutation>;
export type CancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CancelAppointmentMutation,
  CancelAppointmentMutationVariables
>;
export const MarkGuidedJourneyCompleteDocument = gql`
  mutation MarkGuidedJourneyComplete(
    $patientId: UUID!
    $programId: UUID!
    $id: String!
    $guidedJourneyId: String!
    $taskId: UUID = null
  ) {
    createContentView(
      input: {
        patientId: $patientId
        programId: $programId
        contentId: $id
        contentType: PAGE
        parentContentId: $guidedJourneyId
        parentContentType: GUIDED_JOURNEY
        isContentAssignmentComplete: true
        taskId: $taskId
      }
    ) {
      id
      contentId
    }
  }
`;
export type MarkGuidedJourneyCompleteMutationFn = Apollo.MutationFunction<
  MarkGuidedJourneyCompleteMutation,
  MarkGuidedJourneyCompleteMutationVariables
>;

/**
 * __useMarkGuidedJourneyCompleteMutation__
 *
 * To run a mutation, you first call `useMarkGuidedJourneyCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkGuidedJourneyCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markGuidedJourneyCompleteMutation, { data, loading, error }] = useMarkGuidedJourneyCompleteMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      id: // value for 'id'
 *      guidedJourneyId: // value for 'guidedJourneyId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useMarkGuidedJourneyCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkGuidedJourneyCompleteMutation,
    MarkGuidedJourneyCompleteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkGuidedJourneyCompleteMutation,
    MarkGuidedJourneyCompleteMutationVariables
  >(MarkGuidedJourneyCompleteDocument, options);
}
export type MarkGuidedJourneyCompleteMutationHookResult = ReturnType<
  typeof useMarkGuidedJourneyCompleteMutation
>;
export type MarkGuidedJourneyCompleteMutationResult =
  Apollo.MutationResult<MarkGuidedJourneyCompleteMutation>;
export type MarkGuidedJourneyCompleteMutationOptions =
  Apollo.BaseMutationOptions<
    MarkGuidedJourneyCompleteMutation,
    MarkGuidedJourneyCompleteMutationVariables
  >;
export const MarkGuidedJourneyPageReadDocument = gql`
  mutation MarkGuidedJourneyPageRead(
    $patientId: UUID!
    $programId: UUID!
    $id: String!
    $guidedJourneyId: String!
    $taskId: UUID = null
  ) {
    createContentView(
      input: {
        patientId: $patientId
        programId: $programId
        contentId: $id
        contentType: PAGE
        isContentAssignmentComplete: false
        parentContentId: $guidedJourneyId
        parentContentType: GUIDED_JOURNEY
        taskId: $taskId
      }
    ) {
      id
      contentId
    }
  }
`;
export type MarkGuidedJourneyPageReadMutationFn = Apollo.MutationFunction<
  MarkGuidedJourneyPageReadMutation,
  MarkGuidedJourneyPageReadMutationVariables
>;

/**
 * __useMarkGuidedJourneyPageReadMutation__
 *
 * To run a mutation, you first call `useMarkGuidedJourneyPageReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkGuidedJourneyPageReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markGuidedJourneyPageReadMutation, { data, loading, error }] = useMarkGuidedJourneyPageReadMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      id: // value for 'id'
 *      guidedJourneyId: // value for 'guidedJourneyId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useMarkGuidedJourneyPageReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkGuidedJourneyPageReadMutation,
    MarkGuidedJourneyPageReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkGuidedJourneyPageReadMutation,
    MarkGuidedJourneyPageReadMutationVariables
  >(MarkGuidedJourneyPageReadDocument, options);
}
export type MarkGuidedJourneyPageReadMutationHookResult = ReturnType<
  typeof useMarkGuidedJourneyPageReadMutation
>;
export type MarkGuidedJourneyPageReadMutationResult =
  Apollo.MutationResult<MarkGuidedJourneyPageReadMutation>;
export type MarkGuidedJourneyPageReadMutationOptions =
  Apollo.BaseMutationOptions<
    MarkGuidedJourneyPageReadMutation,
    MarkGuidedJourneyPageReadMutationVariables
  >;
export const MarkPageReadDocument = gql`
  mutation MarkPageRead(
    $patientId: UUID!
    $programId: UUID!
    $id: String!
    $taskId: UUID = null
    $markTaskComplete: Boolean = false
  ) {
    createContentView(
      input: {
        patientId: $patientId
        programId: $programId
        contentId: $id
        contentType: PAGE
        isContentAssignmentComplete: $markTaskComplete
        taskId: $taskId
      }
    ) {
      id
      contentId
    }
  }
`;
export type MarkPageReadMutationFn = Apollo.MutationFunction<
  MarkPageReadMutation,
  MarkPageReadMutationVariables
>;

/**
 * __useMarkPageReadMutation__
 *
 * To run a mutation, you first call `useMarkPageReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPageReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPageReadMutation, { data, loading, error }] = useMarkPageReadMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      id: // value for 'id'
 *      taskId: // value for 'taskId'
 *      markTaskComplete: // value for 'markTaskComplete'
 *   },
 * });
 */
export function useMarkPageReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkPageReadMutation,
    MarkPageReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkPageReadMutation,
    MarkPageReadMutationVariables
  >(MarkPageReadDocument, options);
}
export type MarkPageReadMutationHookResult = ReturnType<
  typeof useMarkPageReadMutation
>;
export type MarkPageReadMutationResult =
  Apollo.MutationResult<MarkPageReadMutation>;
export type MarkPageReadMutationOptions = Apollo.BaseMutationOptions<
  MarkPageReadMutation,
  MarkPageReadMutationVariables
>;
export const CreateAppointmentDocument = gql`
  mutation CreateAppointment($input: CreateAppointmentInput!) {
    createAppointment(input: $input) {
      id
      appointmentTypeId
      appointmentType {
        displayName
      }
      patientId
      staffId
      staff {
        firstName
        lastName
        preferredName
        professionalLicenses {
          licenseState
          type
          createdAt
          id
          licenseNumber
          status
          updatedAt
        }
        profileImageUrl
      }
      videoConferenceUrl
      startTime
    }
  }
`;
export type CreateAppointmentMutationFn = Apollo.MutationFunction<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;

/**
 * __useCreateAppointmentMutation__
 *
 * To run a mutation, you first call `useCreateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAppointmentMutation, { data, loading, error }] = useCreateAppointmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAppointmentMutation,
    CreateAppointmentMutationVariables
  >(CreateAppointmentDocument, options);
}
export type CreateAppointmentMutationHookResult = ReturnType<
  typeof useCreateAppointmentMutation
>;
export type CreateAppointmentMutationResult =
  Apollo.MutationResult<CreateAppointmentMutation>;
export type CreateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAppointmentMutation,
  CreateAppointmentMutationVariables
>;
export const CreateEnrollmentDocument = gql`
  mutation CreateEnrollment(
    $patientId: UUID!
    $programSlug: String!
    $firstName: String!
    $lastName: String!
    $birthDate: Date!
    $postalCode: String!
  ) {
    createEnrollment(
      input: {
        patientId: $patientId
        programSlug: $programSlug
        employeeDetails: {
          firstName: $firstName
          lastName: $lastName
          birthDate: $birthDate
          zipCode: $postalCode
        }
      }
    ) {
      id
      createdAt
      updatedAt
      programId
      enrollmentStatus
    }
  }
`;
export type CreateEnrollmentMutationFn = Apollo.MutationFunction<
  CreateEnrollmentMutation,
  CreateEnrollmentMutationVariables
>;

/**
 * __useCreateEnrollmentMutation__
 *
 * To run a mutation, you first call `useCreateEnrollmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEnrollmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEnrollmentMutation, { data, loading, error }] = useCreateEnrollmentMutation({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programSlug: // value for 'programSlug'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      birthDate: // value for 'birthDate'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useCreateEnrollmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateEnrollmentMutation,
    CreateEnrollmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateEnrollmentMutation,
    CreateEnrollmentMutationVariables
  >(CreateEnrollmentDocument, options);
}
export type CreateEnrollmentMutationHookResult = ReturnType<
  typeof useCreateEnrollmentMutation
>;
export type CreateEnrollmentMutationResult =
  Apollo.MutationResult<CreateEnrollmentMutation>;
export type CreateEnrollmentMutationOptions = Apollo.BaseMutationOptions<
  CreateEnrollmentMutation,
  CreateEnrollmentMutationVariables
>;
export const CreatePatientDocument = gql`
  mutation CreatePatient($input: CreatePatientInput!) {
    createPatient(input: $input) {
      id
      firstName
      lastName
      birthDate
      birthSex
      addresses {
        city
        line1
        line2
        state
        postalCode
        country
      }
      phoneNumbers {
        use
        value
      }
      preferredName
      pronouns {
        value
      }
      genderIdentity {
        value
      }
      contactPreference
    }
  }
`;
export type CreatePatientMutationFn = Apollo.MutationFunction<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePatientMutation,
    CreatePatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePatientMutation,
    CreatePatientMutationVariables
  >(CreatePatientDocument, options);
}
export type CreatePatientMutationHookResult = ReturnType<
  typeof useCreatePatientMutation
>;
export type CreatePatientMutationResult =
  Apollo.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = Apollo.BaseMutationOptions<
  CreatePatientMutation,
  CreatePatientMutationVariables
>;
export const UpsertFormResponseByTaskIdDocument = gql`
  mutation UpsertFormResponseByTaskId(
    $taskId: UUID!
    $input: UpsertFormResponseInput!
  ) {
    upsertFormResponseByTaskId(taskId: $taskId, input: $input) {
      id
      patientId
      programId
      formVersionId
      task {
        id
      }
      response
      createdAt
      updatedAt
      submittedAt
      formVersion {
        id
      }
    }
  }
`;
export type UpsertFormResponseByTaskIdMutationFn = Apollo.MutationFunction<
  UpsertFormResponseByTaskIdMutation,
  UpsertFormResponseByTaskIdMutationVariables
>;

/**
 * __useUpsertFormResponseByTaskIdMutation__
 *
 * To run a mutation, you first call `useUpsertFormResponseByTaskIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertFormResponseByTaskIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertFormResponseByTaskIdMutation, { data, loading, error }] = useUpsertFormResponseByTaskIdMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertFormResponseByTaskIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertFormResponseByTaskIdMutation,
    UpsertFormResponseByTaskIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertFormResponseByTaskIdMutation,
    UpsertFormResponseByTaskIdMutationVariables
  >(UpsertFormResponseByTaskIdDocument, options);
}
export type UpsertFormResponseByTaskIdMutationHookResult = ReturnType<
  typeof useUpsertFormResponseByTaskIdMutation
>;
export type UpsertFormResponseByTaskIdMutationResult =
  Apollo.MutationResult<UpsertFormResponseByTaskIdMutation>;
export type UpsertFormResponseByTaskIdMutationOptions =
  Apollo.BaseMutationOptions<
    UpsertFormResponseByTaskIdMutation,
    UpsertFormResponseByTaskIdMutationVariables
  >;
export const ResetUserPasswordDocument = gql`
  mutation resetUserPassword($resetUserPasswordId: UUID!) {
    resetUserPassword(id: $resetUserPasswordId)
  }
`;
export type ResetUserPasswordMutationFn = Apollo.MutationFunction<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;

/**
 * __useResetUserPasswordMutation__
 *
 * To run a mutation, you first call `useResetUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetUserPasswordMutation, { data, loading, error }] = useResetUserPasswordMutation({
 *   variables: {
 *      resetUserPasswordId: // value for 'resetUserPasswordId'
 *   },
 * });
 */
export function useResetUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetUserPasswordMutation,
    ResetUserPasswordMutationVariables
  >(ResetUserPasswordDocument, options);
}
export type ResetUserPasswordMutationHookResult = ReturnType<
  typeof useResetUserPasswordMutation
>;
export type ResetUserPasswordMutationResult =
  Apollo.MutationResult<ResetUserPasswordMutation>;
export type ResetUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetUserPasswordMutation,
  ResetUserPasswordMutationVariables
>;
export const UpdatePatientDocument = gql`
  mutation updatePatient($updatePatientId: UUID!, $input: UpdatePatientInput!) {
    updatePatient(id: $updatePatientId, input: $input) {
      firstName
    }
  }
`;
export type UpdatePatientMutationFn = Apollo.MutationFunction<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      updatePatientId: // value for 'updatePatientId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePatientMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePatientMutation,
    UpdatePatientMutationVariables
  >(UpdatePatientDocument, options);
}
export type UpdatePatientMutationHookResult = ReturnType<
  typeof useUpdatePatientMutation
>;
export type UpdatePatientMutationResult =
  Apollo.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = Apollo.BaseMutationOptions<
  UpdatePatientMutation,
  UpdatePatientMutationVariables
>;
export const SendPatientEmailVerificationDocument = gql`
  mutation SendPatientEmailVerification(
    $sendPatientEmailVerificationId: UUID!
  ) {
    sendPatientEmailVerification(id: $sendPatientEmailVerificationId) {
      status
    }
  }
`;
export type SendPatientEmailVerificationMutationFn = Apollo.MutationFunction<
  SendPatientEmailVerificationMutation,
  SendPatientEmailVerificationMutationVariables
>;

/**
 * __useSendPatientEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSendPatientEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPatientEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPatientEmailVerificationMutation, { data, loading, error }] = useSendPatientEmailVerificationMutation({
 *   variables: {
 *      sendPatientEmailVerificationId: // value for 'sendPatientEmailVerificationId'
 *   },
 * });
 */
export function useSendPatientEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPatientEmailVerificationMutation,
    SendPatientEmailVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SendPatientEmailVerificationMutation,
    SendPatientEmailVerificationMutationVariables
  >(SendPatientEmailVerificationDocument, options);
}
export type SendPatientEmailVerificationMutationHookResult = ReturnType<
  typeof useSendPatientEmailVerificationMutation
>;
export type SendPatientEmailVerificationMutationResult =
  Apollo.MutationResult<SendPatientEmailVerificationMutation>;
export type SendPatientEmailVerificationMutationOptions =
  Apollo.BaseMutationOptions<
    SendPatientEmailVerificationMutation,
    SendPatientEmailVerificationMutationVariables
  >;
export const UpdateAppointmentDocument = gql`
  mutation UpdateAppointment(
    $updateAppointmentId: UUID!
    $input: UpdateAppointmentInput!
  ) {
    updateAppointment(id: $updateAppointmentId, input: $input) {
      id
      programId
      appointmentTypeId
      appointmentType {
        id
        programId
        name
        slug
        displayName
        duration
        descriptionTemplate
        allowedRole
        postFormId
        createdAt
        updatedAt
      }
      taskId
      patientId
      staffId
      staff {
        bio
        firstName
        lastName
        preferredName
        professionalLicenses {
          licenseState
          type
          createdAt
          id
          licenseNumber
          status
          updatedAt
        }
        profileImageUrl
        programs {
          programId
          status
          createdAt
          id
          updatedAt
          program {
            createdAt
            description
            id
            name
            slug
            updatedAt
          }
        }
        role
        status
        createdAt
        updatedAt
        id
      }
      summary
      description
      startTime
      endTime
      timezone
      status
      createdByType
      createdBy
      updatedByType
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export type UpdateAppointmentMutationFn = Apollo.MutationFunction<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;

/**
 * __useUpdateAppointmentMutation__
 *
 * To run a mutation, you first call `useUpdateAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAppointmentMutation, { data, loading, error }] = useUpdateAppointmentMutation({
 *   variables: {
 *      updateAppointmentId: // value for 'updateAppointmentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAppointmentMutation,
    UpdateAppointmentMutationVariables
  >(UpdateAppointmentDocument, options);
}
export type UpdateAppointmentMutationHookResult = ReturnType<
  typeof useUpdateAppointmentMutation
>;
export type UpdateAppointmentMutationResult =
  Apollo.MutationResult<UpdateAppointmentMutation>;
export type UpdateAppointmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAppointmentMutation,
  UpdateAppointmentMutationVariables
>;
export const AppointmentDocument = gql`
  query Appointment($appointmentId: UUID!) {
    appointment(id: $appointmentId) {
      id
      programId
      appointmentTypeId
      appointmentType {
        id
        programId
        name
        slug
        displayName
        duration
        descriptionTemplate
        allowedRole
        postFormId
        createdAt
        updatedAt
      }
      taskId
      patientId
      staffId
      staff {
        bio
        firstName
        lastName
        preferredName
        professionalLicenses {
          licenseState
          type
          createdAt
          id
          licenseNumber
          status
          updatedAt
        }
        profileImageUrl
        programs {
          programId
          status
          createdAt
          id
          updatedAt
          program {
            createdAt
            description
            id
            name
            slug
            updatedAt
          }
        }
        role
        status
        createdAt
        updatedAt
        id
      }
      summary
      description
      startTime
      endTime
      timezone
      status
      createdByType
      createdBy
      updatedByType
      updatedBy
      createdAt
      updatedAt
      videoConferenceUrl
      patient {
        id
        createdAt
        updatedAt
        firstName
        lastName
        birthSex
        birthDate
        preferredName
        genderIdentity {
          value
          text
        }
        pronouns {
          value
          text
        }
        phoneNumbers {
          value
          use
        }
        emailAddresses {
          value
          use
        }
        addresses {
          line1
          line2
          city
          state
          postalCode
          country
        }
        contactPreference
        enrollments {
          id
          createdAt
          updatedAt
          programId
          enrollmentStatus
          enrolledUntil
          enrolledAt
          unenrolledAt
          payer {
            type
            organizationId
            organizationEmployeeId
            eligibilityType
          }
          program {
            id
            createdAt
            updatedAt
            name
            slug
            description
            config {
              roleNameMapping
            }
          }
        }
      }
    }
  }
`;

/**
 * __useAppointmentQuery__
 *
 * To run a query within a React component, call `useAppointmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppointmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppointmentQuery({
 *   variables: {
 *      appointmentId: // value for 'appointmentId'
 *   },
 * });
 */
export function useAppointmentQuery(
  baseOptions: Apollo.QueryHookOptions<
    AppointmentQuery,
    AppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AppointmentQuery, AppointmentQueryVariables>(
    AppointmentDocument,
    options
  );
}
export function useAppointmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AppointmentQuery,
    AppointmentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AppointmentQuery, AppointmentQueryVariables>(
    AppointmentDocument,
    options
  );
}
export type AppointmentQueryHookResult = ReturnType<typeof useAppointmentQuery>;
export type AppointmentLazyQueryHookResult = ReturnType<
  typeof useAppointmentLazyQuery
>;
export type AppointmentQueryResult = Apollo.QueryResult<
  AppointmentQuery,
  AppointmentQueryVariables
>;
export const EligibilityWithAllowanceDocument = gql`
  query EligibilityWithAllowance(
    $programSlug: String!
    $firstName: String!
    $lastName: String!
    $birthDate: Date!
    $postalCode: String!
  ) {
    eligibilityWithAllowance(
      programSlug: $programSlug
      input: {
        firstName: $firstName
        lastName: $lastName
        birthDate: $birthDate
        zipCode: $postalCode
      }
    ) {
      isEligible
      ineligibleReason
    }
  }
`;

/**
 * __useEligibilityWithAllowanceQuery__
 *
 * To run a query within a React component, call `useEligibilityWithAllowanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useEligibilityWithAllowanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEligibilityWithAllowanceQuery({
 *   variables: {
 *      programSlug: // value for 'programSlug'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      birthDate: // value for 'birthDate'
 *      postalCode: // value for 'postalCode'
 *   },
 * });
 */
export function useEligibilityWithAllowanceQuery(
  baseOptions: Apollo.QueryHookOptions<
    EligibilityWithAllowanceQuery,
    EligibilityWithAllowanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    EligibilityWithAllowanceQuery,
    EligibilityWithAllowanceQueryVariables
  >(EligibilityWithAllowanceDocument, options);
}
export function useEligibilityWithAllowanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EligibilityWithAllowanceQuery,
    EligibilityWithAllowanceQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    EligibilityWithAllowanceQuery,
    EligibilityWithAllowanceQueryVariables
  >(EligibilityWithAllowanceDocument, options);
}
export type EligibilityWithAllowanceQueryHookResult = ReturnType<
  typeof useEligibilityWithAllowanceQuery
>;
export type EligibilityWithAllowanceLazyQueryHookResult = ReturnType<
  typeof useEligibilityWithAllowanceLazyQuery
>;
export type EligibilityWithAllowanceQueryResult = Apollo.QueryResult<
  EligibilityWithAllowanceQuery,
  EligibilityWithAllowanceQueryVariables
>;
export const GetEncounterByIdDocument = gql`
  query GetEncounterById($encounterId: UUID!) {
    encounter(id: $encounterId) {
      id
      patientId
      staff {
        professionalLicenses {
          type
        }
        firstName
        id
        lastName
        preferredName
        profileImageUrl
      }
      appointment {
        appointmentType {
          name
        }
        startTime
        videoConferenceUrl
      }
      clinicalNote {
        body {
          ... on HealthCoachNote {
            ...HealthCoachClinicalNote
          }
          ... on PrescriberNote {
            ...PrescriberClinicalNote
          }
          ... on SOAPNote {
            ...HistoricalClinicalNote
          }
        }
      }
      clinicalNoteType
    }
  }
  ${HealthCoachClinicalNoteFragmentDoc}
  ${PrescriberClinicalNoteFragmentDoc}
  ${HistoricalClinicalNoteFragmentDoc}
`;

/**
 * __useGetEncounterByIdQuery__
 *
 * To run a query within a React component, call `useGetEncounterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEncounterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEncounterByIdQuery({
 *   variables: {
 *      encounterId: // value for 'encounterId'
 *   },
 * });
 */
export function useGetEncounterByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEncounterByIdQuery,
    GetEncounterByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEncounterByIdQuery, GetEncounterByIdQueryVariables>(
    GetEncounterByIdDocument,
    options
  );
}
export function useGetEncounterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEncounterByIdQuery,
    GetEncounterByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEncounterByIdQuery,
    GetEncounterByIdQueryVariables
  >(GetEncounterByIdDocument, options);
}
export type GetEncounterByIdQueryHookResult = ReturnType<
  typeof useGetEncounterByIdQuery
>;
export type GetEncounterByIdLazyQueryHookResult = ReturnType<
  typeof useGetEncounterByIdLazyQuery
>;
export type GetEncounterByIdQueryResult = Apollo.QueryResult<
  GetEncounterByIdQuery,
  GetEncounterByIdQueryVariables
>;
export const ListEncountersDocument = gql`
  query ListEncounters($filters: ListEncounterFilters) {
    encounters(filters: $filters) {
      id
      patientId
      summary {
        note
        nextSteps
        goals
      }
      staff {
        professionalLicenses {
          type
        }
        firstName
        id
        lastName
        preferredName
        profileImageUrl
      }
      appointment {
        appointmentType {
          name
        }
        startTime
        videoConferenceUrl
      }
      clinicalNoteType
    }
  }
`;

/**
 * __useListEncountersQuery__
 *
 * To run a query within a React component, call `useListEncountersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEncountersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEncountersQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useListEncountersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ListEncountersQuery,
    ListEncountersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListEncountersQuery, ListEncountersQueryVariables>(
    ListEncountersDocument,
    options
  );
}
export function useListEncountersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ListEncountersQuery,
    ListEncountersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListEncountersQuery, ListEncountersQueryVariables>(
    ListEncountersDocument,
    options
  );
}
export type ListEncountersQueryHookResult = ReturnType<
  typeof useListEncountersQuery
>;
export type ListEncountersLazyQueryHookResult = ReturnType<
  typeof useListEncountersLazyQuery
>;
export type ListEncountersQueryResult = Apollo.QueryResult<
  ListEncountersQuery,
  ListEncountersQueryVariables
>;
export const GuidedJourneyReadPositionDocument = gql`
  query GuidedJourneyReadPosition(
    $patientId: UUID!
    $programId: UUID!
    $guidedJourneyId: String!
    $taskId: UUID = null
  ) {
    contentViews(
      filters: {
        patientId: $patientId
        programId: $programId
        parentContentId: $guidedJourneyId
        taskId: $taskId
        dedupe: true
      }
    ) {
      ...GuidedJourneyReadPositionDetails
    }
  }
  ${GuidedJourneyReadPositionDetailsFragmentDoc}
`;

/**
 * __useGuidedJourneyReadPositionQuery__
 *
 * To run a query within a React component, call `useGuidedJourneyReadPositionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedJourneyReadPositionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedJourneyReadPositionQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *      programId: // value for 'programId'
 *      guidedJourneyId: // value for 'guidedJourneyId'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGuidedJourneyReadPositionQuery(
  baseOptions: Apollo.QueryHookOptions<
    GuidedJourneyReadPositionQuery,
    GuidedJourneyReadPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GuidedJourneyReadPositionQuery,
    GuidedJourneyReadPositionQueryVariables
  >(GuidedJourneyReadPositionDocument, options);
}
export function useGuidedJourneyReadPositionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GuidedJourneyReadPositionQuery,
    GuidedJourneyReadPositionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GuidedJourneyReadPositionQuery,
    GuidedJourneyReadPositionQueryVariables
  >(GuidedJourneyReadPositionDocument, options);
}
export type GuidedJourneyReadPositionQueryHookResult = ReturnType<
  typeof useGuidedJourneyReadPositionQuery
>;
export type GuidedJourneyReadPositionLazyQueryHookResult = ReturnType<
  typeof useGuidedJourneyReadPositionLazyQuery
>;
export type GuidedJourneyReadPositionQueryResult = Apollo.QueryResult<
  GuidedJourneyReadPositionQuery,
  GuidedJourneyReadPositionQueryVariables
>;
export const GetFormByIdDocument = gql`
  query GetFormById($formId: UUID!) {
    form(id: $formId) {
      id
      draftVersion {
        config
        id
      }
      archivedAt
      createdAt
      description
      name
      programId
      publishedVersion {
        config
        id
      }
      slug
      updatedAt
    }
  }
`;

/**
 * __useGetFormByIdQuery__
 *
 * To run a query within a React component, call `useGetFormByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormByIdQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useGetFormByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormByIdQuery,
    GetFormByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(
    GetFormByIdDocument,
    options
  );
}
export function useGetFormByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormByIdQuery,
    GetFormByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFormByIdQuery, GetFormByIdQueryVariables>(
    GetFormByIdDocument,
    options
  );
}
export type GetFormByIdQueryHookResult = ReturnType<typeof useGetFormByIdQuery>;
export type GetFormByIdLazyQueryHookResult = ReturnType<
  typeof useGetFormByIdLazyQuery
>;
export type GetFormByIdQueryResult = Apollo.QueryResult<
  GetFormByIdQuery,
  GetFormByIdQueryVariables
>;
export const GetFormResponseByTaskIdDocument = gql`
  query GetFormResponseByTaskId($taskId: UUID!) {
    formResponseByTaskId(taskId: $taskId) {
      id
      response
      pageNumber
    }
  }
`;

/**
 * __useGetFormResponseByTaskIdQuery__
 *
 * To run a query within a React component, call `useGetFormResponseByTaskIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormResponseByTaskIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormResponseByTaskIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useGetFormResponseByTaskIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFormResponseByTaskIdQuery,
    GetFormResponseByTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFormResponseByTaskIdQuery,
    GetFormResponseByTaskIdQueryVariables
  >(GetFormResponseByTaskIdDocument, options);
}
export function useGetFormResponseByTaskIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFormResponseByTaskIdQuery,
    GetFormResponseByTaskIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFormResponseByTaskIdQuery,
    GetFormResponseByTaskIdQueryVariables
  >(GetFormResponseByTaskIdDocument, options);
}
export type GetFormResponseByTaskIdQueryHookResult = ReturnType<
  typeof useGetFormResponseByTaskIdQuery
>;
export type GetFormResponseByTaskIdLazyQueryHookResult = ReturnType<
  typeof useGetFormResponseByTaskIdLazyQuery
>;
export type GetFormResponseByTaskIdQueryResult = Apollo.QueryResult<
  GetFormResponseByTaskIdQuery,
  GetFormResponseByTaskIdQueryVariables
>;
export const TaskDocument = gql`
  query Task($taskId: UUID!) {
    task(id: $taskId) {
      id
      eventDueAt
    }
  }
`;

/**
 * __useTaskQuery__
 *
 * To run a query within a React component, call `useTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskQuery(
  baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
}
export function useTaskLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(
    TaskDocument,
    options
  );
}
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TaskObjectRouteDocument = gql`
  query TaskObjectRoute($taskId: UUID!) {
    task(id: $taskId) {
      id
      name
      status
      taskDueAt
      taskExpireAt
      taskType
      relatedObjectType
      relatedObjectId
    }
  }
`;

/**
 * __useTaskObjectRouteQuery__
 *
 * To run a query within a React component, call `useTaskObjectRouteQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskObjectRouteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskObjectRouteQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useTaskObjectRouteQuery(
  baseOptions: Apollo.QueryHookOptions<
    TaskObjectRouteQuery,
    TaskObjectRouteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TaskObjectRouteQuery, TaskObjectRouteQueryVariables>(
    TaskObjectRouteDocument,
    options
  );
}
export function useTaskObjectRouteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TaskObjectRouteQuery,
    TaskObjectRouteQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TaskObjectRouteQuery,
    TaskObjectRouteQueryVariables
  >(TaskObjectRouteDocument, options);
}
export type TaskObjectRouteQueryHookResult = ReturnType<
  typeof useTaskObjectRouteQuery
>;
export type TaskObjectRouteLazyQueryHookResult = ReturnType<
  typeof useTaskObjectRouteLazyQuery
>;
export type TaskObjectRouteQueryResult = Apollo.QueryResult<
  TaskObjectRouteQuery,
  TaskObjectRouteQueryVariables
>;
export const WhoAmIDocument = gql`
  query WhoAmI {
    whoAmI {
      id
      firstName
      lastName
      birthDate
      birthSex
      createdAt
      updatedAt
      preferredName
      contactPreference
      genderIdentity {
        text
        value
      }
      phoneNumbers {
        use
        value
      }
      emailAddresses {
        value
      }
      pronouns {
        text
        value
      }
      addresses {
        city
        line1
        line2
        state
        postalCode
        country
      }
      enrollments {
        id
        createdAt
        updatedAt
        programId
        enrollmentStatus
        enrolledUntil
        enrolledAt
        unenrolledAt
        payer {
          organizationEmployeeId
          organizationId
          type
        }
        program {
          config {
            roleNameMapping
          }
          createdAt
          description
          id
          name
          slug
          updatedAt
        }
      }
    }
  }
`;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(
  baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    options
  );
}
export function useWhoAmILazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(
    WhoAmIDocument,
    options
  );
}
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<
  WhoAmIQuery,
  WhoAmIQueryVariables
>;
